import { computed, MaybeRef, unref } from 'vue'
import { useCurrentCompanyQuery } from '@/api/useCompany'
import { Company, Feature } from '@/api/useCompany.types'

/**
 * @deprecated use useAbility instead
 */
export const useAccessManual = (company: MaybeRef<Company | undefined>) => {
  const can = (feature: Feature) => {
    return unref(company)?.features.includes(feature) ?? false
  }

  return {
    // TODO: merge with features
    hasWriteAccess: computed(() => !!unref(company)?.hasCurrentUserWriteAccess),
    can,
  }
}

/**
 * @deprecated use useAbility instead
 */
export const useAccess = () => {
  const { data: company } = useCurrentCompanyQuery()

  return useAccessManual(company)
}
